import React, { useEffect, useMemo, useState } from "react";

import { ADD, DELETE, GET } from "../Functions/apiFunction";
import api from "../Data/api";
import "../Styles/buttons.css";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../theme";
import {
  DrawingManager,
  GoogleMap,
  LoadScript,
  Polygon,
} from "@react-google-maps/api";
import {
  Backdrop,
  Box,
  CircularProgress,
  Skeleton,
  Stack,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90vw", sm: 500, md: 500, lg: 500, xl: 500 },
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "8px",
  p: 2,
};

const GeoLocations = () => {
  const apiKey = "AIzaSyAsIZSHIrt7DuiALc6FbfvzKypr-v4QqeA";
  const [polygons, setPolygons] = useState([]);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [pincode, setpincode] = useState();

  const [reFetch, setreFetch] = useState(false);

  const [isUpdating, setisUpdating] = useState(false);

  const admin = JSON.parse(sessionStorage.getItem("admin"));
  const token = `Bearer ${admin.token}`;
  // update user state

  // add location
  const AddGeoFensing = async (data) => {
    const url = `${api}/add_geofencing`;
    setisUpdating(true);
    const addsubcat = await ADD(token, url, data);
    console.log(addsubcat);
    if (addsubcat.response === 200) {
      setisUpdating(false);
      setreFetch(!reFetch);
      setisUpdating(false);
    } else if (addsubcat.response === 201) {
      setisUpdating(false);
      setisUpdating(false);
    } else {
      setisUpdating(false);
      setisUpdating(false);
    }
  };

  // delete
  const deleteCat = async (data) => {
    const url = `${api}/delete_geofencing`;
    setisUpdating(true);
    const deleteGoe = await DELETE(token, url, data);
    setreFetch(!reFetch);
    setisUpdating(false);
    setreFetch(!reFetch);
    console.log(deleteGoe);
  };

  useEffect(() => {
    // Get categoriues
    const getCat = async () => {
      const url = `${api}/get_geofencing`;
      const data = await GET(token, url);
      setpincode(data.data);
      setPolygons(data.data);
      console.log(data.data);
    };
    getCat();
  }, [reFetch, token]);

  // user location
  const [userLocation, setUserLocation] = useState(null);

  useEffect(() => {
    // Get user's current location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ lat: latitude, lng: longitude });
        },
        (error) => {
          console.error("Error getting user location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  const handlePolygonComplete = (polygon) => {
    const areaName = prompt("Enter the name of the area:");
    if (areaName) {
      const paths = polygon
        .getPath()
        .getArray()
        .map((point) => ({
          lat: point.lat(),
          lng: point.lng(),
        }));

      // Add the area name to each polygon object
      const newPolygon = {
        area: areaName,
        value: JSON.stringify(paths),
      };
      AddGeoFensing(newPolygon);
      console.log(newPolygon);
    }
  };

  const column = useMemo(
    () => [
      { field: "area", headerName: "Name", width: 180 },

      {
        field: "Delete",
        headerName: "Delete",
        width: 100,
        renderCell: (params) => (
          <button
            class="dltBtn"
            onClick={() => {
              deleteCat({ id: params.row.id });
            }}
          >
            <span class="icon">
              <i class="fa-solid fa-trash"></i>
            </span>
          </button>
        ),
      },
    ],
    []
  );
  const getPaths = (str) => {
    return JSON.parse(str);
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isUpdating}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          paddingBottom: "10px",
          borderBottom:
            theme.palette.mode === "dark"
              ? "0.5px solid #E1E3E6"
              : "1px solid #757D8A",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2 className="heading">Manage Delivery Areas </h2>
        </div>
      </Box>
      <Box display={"flex"} alignItems={"center"} gap={"30px"} mt={5}>
        {" "}
        <LoadScript googleMapsApiKey={apiKey} libraries={["drawing"]}>
          <GoogleMap
            mapContainerStyle={{ width: "100%", height: "80vh" }}
            zoom={userLocation ? 10 : 10} // Zoom to user's location if available, otherwise default zoom
            center={{ lat: 25.276987, lng: 55.296249 }} // Center map on user's location if available
          >
            <DrawingManager
              onPolygonComplete={handlePolygonComplete}
              onCircleComplete={handlePolygonComplete}
              options={{
                drawingControl: true,
                drawingControlOptions: {
                  drawingModes: ["polygon"],
                },
                polygonOptions: {
                  fillColor: "#6870fa",
                  fillOpacity: 0.5,
                  strokeWeight: 2,
                  clickable: true,
                  editable: true,
                  draggable: true,
                  zIndex: 1,
                },
              }}
            />
            {polygons.map((paths, index) => (
              <Polygon key={index} paths={getPaths(paths.value)} />
            ))}
          </GoogleMap>
        </LoadScript>{" "}
        <Box width={"100%"} sx={{ height: "80vh", width: "100%" }}>
          {pincode ? (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                paddingBottom: "30px",
                "& .MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: "none",
                },
                "& .MuiDataGrid-row": {
                  fontSize: "14px",
                },
                "& .name-column--cell": {
                  color: colors.greenAccent[300],
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: colors.navbarBG[400],
                  borderBottom: "none",
                  color: "#f5f5f5",
                },
                "& .MuiDataGrid-virtualScroller": {
                  backgroundColor: colors.primary[400],
                  borderBottom: "#000",
                },
                "& .MuiDataGrid-footerContainer": {
                  borderTop: "none",
                  backgroundColor: colors.navbarBG[400],
                  color: "#f5f5f5 !important",
                },
                "& .MuiTablePagination-root": {
                  color: "#f5f5f5 !important",
                },
                "& .MuiTablePagination-selectIcon": {
                  color: "#f5f5f5 !important",
                },
                "& .MuiTablePagination-actions botton": {
                  color: "#f5f5f5 !important",
                },
                "& .MuiCheckbox-root": {
                  color: `${colors.greenAccent[200]} !important`,
                },
              }}
            >
              {" "}
              <DataGrid
                sx={{ fontSize: "13px" }}
                columns={column}
                rows={pincode}
              />
            </Box>
          ) : (
            <Stack spacing={1}>
              {/* For variant="text", adjust the height via font-size */}
              <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
              {/* For other variants, adjust the size with `width` and `height` */}

              <Skeleton
                variant="rectangular"
                animation="wave"
                width={"100%"}
                height={30}
              />
              <Skeleton
                variant="rectangular"
                animation="wave"
                width={"100%"}
                height={30}
              />
              <Skeleton
                variant="rectangular"
                animation="wave"
                width={"100%"}
                height={30}
              />
              <Skeleton
                variant="rectangular"
                animation="wave"
                width={"100%"}
                height={30}
              />
              <Skeleton
                variant="rectangular"
                animation="wave"
                width={"100%"}
                height={30}
              />
              <Skeleton
                variant="rectangular"
                animation="wave"
                width={"100%"}
                height={30}
              />
            </Stack>
          )}
        </Box>
      </Box>{" "}
    </>
  );
};

export default GeoLocations;
